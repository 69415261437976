import { Button, useEnqueueToast, type ButtonProps } from '@orus.eu/pharaoh'
import { useCallback, type ReactElement } from 'react'
import { copyToClipboard } from '../../lib/clipboard-util'

export type CopyToClipboardButtonProps = ButtonProps & {
  stringToCopy?: string
  message?: string
  autoHideDuration?: number
}

export function CopyToClipboardButton(props: CopyToClipboardButtonProps): ReactElement {
  const { stringToCopy, children, disabled, message, autoHideDuration, ...passedProps } = props
  const { enqueueToast } = useEnqueueToast()

  const clickHandler = useCallback(() => {
    if (!stringToCopy) {
      return
    }
    copyToClipboard(stringToCopy)
      .then(() => {
        if (message) enqueueToast(message, { variant: 'success', autoHideDuration })
      })
      .catch((err: unknown) => {
        alert("Impossible d'accéder au presse-papier")
        throw err
      })
  }, [stringToCopy, enqueueToast, message, autoHideDuration])

  return (
    <Button {...passedProps} onClick={clickHandler} disabled={disabled || !stringToCopy}>
      {children}
    </Button>
  )
}
